import { ethers } from "ethers";

const convertWeiToEth = (val) => {
  return ethers.utils.formatUnits(val);
};

const convertBigNumberToNumber = (num = 0, convert = false) => {
  if (typeof num?.toNumber === "function") {
    return convert ? parseFloat(convertWeiToEth(num)) : num.toNumber();
  }

  return num ?? 0;
};

// "function paymentTokenAddress() view returns(address)",
// "function paymentTokenAllowance() view returns(uint256)",
// "function paymentTokenBalance() view returns(uint256)",
// "function paymentTokenPrice() view returns(uint256)",

export default class Contract {
  constructor({ address, chainId, key, price, group, eligible, isPublic, isClosed, maxTokens, nextToken, maxMintPerAddress, maxMintPerTransaction, tokensMinted, balanceOf, priceUnit = "ETH", paymentTokenAllowance, paymentTokenBalance, paymentTokenPrice, paymentTokenSymbol, paymentTokenAddress } = {}, registrant = {}, { useEligibleCheck = false, maxTransaction = "both", maxWallet = "both" } = {}) {
    this.address = address;
    this.chainId = chainId;
    this.priceUnit = priceUnit;
    this.key = key;
    this.price = convertBigNumberToNumber(price, true);
    this.priceDescription = `${this.price} ${priceUnit}`;
    this.eligible = eligible;
    this.group = group;
    let groupMatch = new RegExp("group[0-9]+");
    this.groupNumber = groupMatch.test(this.group) ? parseInt(this.group.replace("group", "")) : null;
    this.isPublic = isPublic;
    this.isClosed = isClosed;
    this.maxTokens = convertBigNumberToNumber(maxTokens);
    this.nextToken = convertBigNumberToNumber(nextToken);
    this.maxMintPerAddress = convertBigNumberToNumber(maxMintPerAddress);
    this.maxMintPerTransaction = convertBigNumberToNumber(maxMintPerTransaction);

    this.paymentTokenAddress = paymentTokenAddress;
    this.paymentTokenAllowance = convertBigNumberToNumber(paymentTokenAllowance, true);
    this.paymentTokenBalance = convertBigNumberToNumber(paymentTokenBalance, true);
    this.paymentTokenPrice = convertBigNumberToNumber(paymentTokenPrice, true);
    this.paymentTokenSymbol = paymentTokenSymbol;
    this.paymentTokenDescription = `${this.paymentTokenPrice} ${this.paymentTokenSymbol}`;

    if (maxTransaction === "presale") {
      this.maxMintPerTransaction = this.isPublic ? 0 : this.maxMintPerTransaction;
    } else if (maxTransaction === "public") {
      this.maxMintPerTransaction = !this.isPublic ? 0 : this.maxMintPerTransaction;
    }

    if (maxWallet === "presale") {
      this.maxMintPerAddress = this.isPublic ? 0 : this.maxMintPerAddress;
    } else if (maxWallet === "public") {
      this.maxMintPerAddress = !this.isPublic ? 0 : this.maxMintPerAddress;
    }

    this.tokensMinted = convertBigNumberToNumber(tokensMinted);
    this.balanceOf = convertBigNumberToNumber(balanceOf);

    this.tokensLeft = this.maxTokens > 0 ? this.maxTokens - this.nextToken : Infinity;
    this.isSoldOut = !(this.tokensLeft > 0);

    let maxMintPerAddressCheck = this.maxMintPerAddress ? this.maxMintPerAddress - this.tokensMinted : Infinity;
    let maxMintPerTransactionCheck = this.maxMintPerTransaction ? this.maxMintPerTransaction : Infinity;
    let maxMintableCheck = Math.min(maxMintPerAddressCheck, maxMintPerTransactionCheck, this.tokensLeft);
    this.maxMintable = maxMintableCheck > 50 ? 50 : maxMintableCheck;

    let phase = "pending";
    if (this.tokensLeft < 1) {
      phase = "closed_sold_out";
    } else if (this.isClosed && this.group === `group${this.groupNumber}`) {
      phase = "closed_pending";
    } else if (this.isClosed) {
      phase = "closed";
    } else if (this.isPublic) {
      phase = "open_public";
    } else if (this.group === "group") {
      phase = "pending_presale_paused";
    } else if (this.group === `group${this.groupNumber}`) {
      phase = "open_presale";
    }

    this.phase = phase;
    this.isRegisterable = ["pending", "closed_pending", "pending_presale_paused", "open_presale"].includes(this.phase);

    this.registrantGroup = registrant?.group ? parseInt(registrant.group) : null;
    this.registrantAddress = registrant?.address ?? null;

    let registrantPhase = "pending_public";

    if (phase === "closed" || phase === "closed_pending" || phase === "closed_sold_out") {
      registrantPhase = "no_closed";
    } else if (phase === "open_public") {
      registrantPhase = "yes_public";
    } else if (phase === "pending_presale_paused") {
      registrantPhase = this.registrantGroup ? "pending_group" : "pending_public";
    } else if (phase === "open_presale") {
      registrantPhase = this.registrantGroup ? (this.registrantGroup > this.groupNumber ? "pending_group" : "yes_group") : registrant.is_presale ? "pending_group_assign" : "pending_public";
    }

    this.registrantPhase = registrantPhase;

    if (useEligibleCheck && !this.eligible) {
      this.registrantPhase = "ineligible";
    }

    this.isMintable = ["yes_public", "yes_group"].includes(this.registrantPhase);
    this.isMintActive = ["open_public", "open_presale"].includes(this.phase);
  }
}

/*
GLOBAL
group === init; pending
group === group; pending_presale_paused
group === group{int}; open_presale

group === group{int} && isClosed; closed_pending
group !== group{int} && isClosed; closed
isPublic && !isClosed; open_public

SELF IN PRESALE
registrant.group <= group{int}; yes_group
registrant.group && registrant.group > group{int}; pending_group
!registrant.group; pending_public

==================
public static $PHASE_PENDING = "pending"; // mint not started
public static $PHASE_OPEN_PRESALE = "open_presale"; // mint open presale - registration required
public static $PHASE_PAUSED_PRESALE = "pending_presale_paused"; // mint paused temporarily in presale
public static $PHASE_OPEN_PUBLIC = "open_public"; // mint public - no registration required
public static $PHASE_CLOSED_PENDING = "closed_pending"; // mint closed - will reopen
public static $PHASE_CLOSED = "closed"; // mint closed

public static $MINTABLE_GROUP = "yes_group"; // you can mint in presale
public static $MINTABLE_PENDING_GROUP = "pending_group"; // you can't mint but can when your group is up
public static $MINTABLE_PENDING_PUBLIC = "pending_public"; // you can't mint but can when public
public static $MINTABLE_PUBLIC = "yes_public"; // everybody can mint
public static $MINTABLE_CLOSED = "no_closed"; // 
*/
