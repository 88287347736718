<script setup>
import { ref, defineProps, computed, onMounted, defineEmits } from "vue"; // onMounted, onUnmounted, computed,
import { ethers } from "ethers";
import axios from "axios";
import Contract from "../classes/Contract";
import TokenContract from "../classes/TokenContract";

const props = defineProps({
  registrant: Object,
  signer: Object,
  provider: Object,
  network: Object,
  address: String,
  bearerToken: String,
  contract: Contract,
  contractInstance: Object,
  projectSlug: String,
  teamSlug: String,
  apiUrl: String,
  loading: Boolean,
  mintingColor: String,
  tokenContractInstance: Object,
});

const locked = ref(false);

const emit = defineEmits(["error", "loaded", "success", "refresh"]);

const mode = ref("eth"); // also token

const switchMode = () => {
  if (mode.value === "eth") {
    mode.value = "token";
  } else {
    mode.value = "eth";
  }
};

const tokenMintAvailable = computed(() => {
  return !!props.tokenContractInstance;
});

const price = computed(() => {
  return props.contract.price;
});

const quantity = ref(1);

const fetchSignature = async () => {
  let sig = await axios.get(`${props.apiUrl}/signature?team_slug=${props.teamSlug}&project_slug=${props.projectSlug}&address=${props.address}&group=${props.contract.groupNumber}`, {
    headers: {
      Authorization: `Bearer ${props.bearerToken}`,
    },
  });
  return sig?.data?.content?.signature;
};

const subtotal = computed(() => {
  if (mode.value === "token") {
    if (!props.contract.paymentTokenPrice) {
      return 0;
    }

    let bigNumAmount = ethers.utils.parseEther(props.contract.paymentTokenPrice.toString());
    let valMultiplied = bigNumAmount.mul(quantity.value);
    let valForTransaction = valMultiplied;
    return ethers.utils.formatUnits(valForTransaction);
  }

  if (!props.contract?.price) {
    return 0;
  }

  let bigNumAmount = ethers.utils.parseEther(price.value.toString());
  let valMultiplied = bigNumAmount.mul(quantity.value);
  let valForTransaction = valMultiplied;
  return ethers.utils.formatUnits(valForTransaction);
  // return multiply(props.contract.price, quantity.value);
});

const shop = async () => {
  emit("loaded", true);
  let amount = quantity.value;
  locked.value = true;
  try {
    let bigNumAmount = ethers.utils.parseEther(price.value.toString());
    let valMultiplied = bigNumAmount.mul(amount);
    let valForTransaction = valMultiplied;

    let shopAttempt;
    if (props.contract?.isPublic) {
      console.log("shop public: ", amount);
      shopAttempt = await props.contractInstance["shop(uint256)"](amount, { value: valForTransaction });
    } else {
      console.log("shop grouped: ", amount);
      let sig = await fetchSignature();
      shopAttempt = await props.contractInstance["shop(uint256,bytes)"](amount, sig, { value: valForTransaction });
    }

    console.log({
      amount,
      price: price.value,
      total: amount * price.value,
      valMultiplied,
      valForTransaction,
    });

    quantity.value = 1;
    handleHashToCheck(shopAttempt.hash);
  } catch (e) {
    console.log("failed mint", e);
    emit("error", e?.message ?? e);
  }
  emit("loaded", false);
  locked.value = false;
};

const hashesToCheck = ref([]);
const addHashToCheck = (hash) => {
  hashesToCheck.value = [...hashesToCheck.value, hash];
};
const removeHashToCheck = (hash) => {
  hashesToCheck.value = [...hashesToCheck.value.filter((h) => h !== hash)];
};

const handleHashToCheck = async (hash) => {
  addHashToCheck(hash);
  let confirmation = await props.provider.waitForTransaction(hash, true);
  console.log({ confirmation });
  emit("success", `Transaction completed!`);
  removeHashToCheck(hash);
};

const getEtherscanLink = (tx) => {
  if (props.network.chainId) {
    switch (props.network.chainId) {
      case "1":
      case 1:
        return `https://etherscan.io/tx/${tx}`;
      case "3":
      case 3:
        return `https://ropsten.etherscan.io/tx/${tx}`;
      case "4":
      case 4:
        return `https://rinkeby.etherscan.io/tx/${tx}`;
      case "5":
      case 5:
        return `https://goerli.etherscan.io/tx/${tx}`;
      case "42":
      case 42:
        return `https://kovan.etherscan.io/tx/${tx}`;
      case "1337":
      case 1337:
        return `https://etherscan.io/tx/${tx}`;
    }
  }
  return null;
};

onMounted(() => {});

const wrongAddressSignature = computed(() => {
  return props.contract?.phase !== "open_public" && props.registrant?.eth_wallet_address && props.registrant?.eth_wallet_address !== props.address;
});

const mintButtonActive = computed(() => {
  if (wrongAddressSignature.value) {
    return false;
  }

  return !locked.value && props.contract?.maxMintable > 0;
});

const tokenAmountAvailable = computed(() => {
  if (mode.value === "eth") {
    return true;
  }

  return subtotal.value <= props.contract?.paymentTokenBalance;
});

const tokenAmountApproved = computed(() => {
  if (mode.value === "eth") {
    return true;
  }

  return subtotal.value <= props.contract?.paymentTokenAllowance;
});

const shopToken = async () => {
  emit("loaded", true);
  let amount = quantity.value;
  locked.value = true;
  try {
    let shopAttempt;
    if (props.contract?.isPublic) {
      console.log("shop public: ", amount);
      shopAttempt = await props.contractInstance["shopToken(uint256)"](amount);
    } else {
      console.log("shop grouped: ", amount);
      let sig = await fetchSignature();
      shopAttempt = await props.contractInstance["shopToken(uint256,bytes)"](amount, sig);
    }

    quantity.value = 1;
    handleHashToCheck(shopAttempt.hash);
  } catch (e) {
    console.log("failed mint", e);
    emit("error", e?.message ?? e);
  }
  emit("loaded", false);
  locked.value = false;
};

const approveTokenAmount = async () => {
  let amount = quantity.value;

  try {
    let bigNumAmount = ethers.utils.parseEther(props.contract?.paymentTokenPrice?.toString());
    let valMultiplied = bigNumAmount.mul(amount);
    let approveAttempt = await props.tokenContractInstance?.approve(props.contract?.address, valMultiplied);

    console.log({
      approveAttempt,
    });
    handleTokenHashToCheck(approveAttempt.hash);
  } catch (e) {
    console.log("failed mint", e);
    emit("error", e?.message ?? e);
  }
};

const tokenHashesToCheck = ref([]);
const addTokenHashToCheck = (hash) => {
  tokenHashesToCheck.value = [...hashesToCheck.value, hash];
};
const removeTokenHashToCheck = (hash) => {
  tokenHashesToCheck.value = [...hashesToCheck.value.filter((h) => h !== hash)];
};

const handleTokenHashToCheck = async (hash) => {
  addTokenHashToCheck(hash);
  let confirmation = await props.provider.waitForTransaction(hash, true);
  console.log({ confirmation });
  emit("refresh");
  removeTokenHashToCheck(hash);
};
</script>

<template>
  <div class="mint-component">
    <form class="mint-form">
      <div style="font-size: 12px; margin-bottom: 12px" v-if="contract?.tokensLeft < Infinity"><strong>Amount Remaining:</strong> {{ contract?.tokensLeft }}</div>

      <div class="mint-form-price">
        <div>Price: {{ mode === "eth" ? contract?.priceDescription : contract?.paymentTokenDescription }} / ea</div>
        <div v-if="tokenMintAvailable" @click="switchMode" class="mint-mode-switch">{{ mode === "eth" ? `Pay with ${contract?.paymentTokenSymbol}` : "Pay with ETH" }}</div>
      </div>
      <div class="mint-maximums">
        <div v-if="contract?.maxMintPerAddress">Max of {{ contract?.maxMintPerAddress }} per wallet</div>
        <div v-if="contract?.maxMintPerTransaction">Max of {{ contract?.maxMintPerTransaction }} per transaction</div>
      </div>
      <div class="" v-if="contract?.maxMintable">
        <label class="label">Quantity</label>
        <select class="mint-quantity-input" v-model="quantity">
          <option v-for="n in contract?.maxMintable" :value="n" :key="n">
            {{ n }}
          </option>
        </select>
      </div>
      <div v-else class="mint-maximums">You have minted the maximum allowed</div>
      <div class="mint-form-subtotal" v-if="contract?.maxMintable">Subtotal (Before Gas): {{ subtotal }} {{ mode === "eth" ? contract?.priceUnit : contract?.paymentTokenSymbol }}</div>
      <template v-if="mode === 'eth'">
        <button class="btn" :class="{ 'btn-green': mintButtonActive, 'btn-disabled': !mintButtonActive }" :style="{ 'background-color': mintButtonActive ? props.mintingColor : '' }" type="button" @click="shop" :disabled="!mintButtonActive">Mint Now</button>
      </template>
      <template v-else>
        <button class="btn" type="button" @click="approveTokenAmount" v-if="!tokenAmountAvailable" :disabled="true">Not enough {{ props.contract?.paymentTokenSymbol }}</button>
        <button class="btn btn-green" type="button" @click="approveTokenAmount" v-else-if="!tokenAmountApproved">Approve {{ props.contract?.paymentTokenSymbol }} amount</button>
        <button class="btn btn-green" type="button" @click="shopToken" v-else>Mint Now</button>
      </template>
      <div class="mint-form-registered-wallet" v-if="wrongAddressSignature">Must use wallet <br />{{ registrant.eth_wallet_address }}</div>
    </form>

    <div class="mint-form-hashes" v-if="tokenHashesToCheck?.length">
      <div v-for="(hash, h) in hashesToCheck" :key="h" class="mint-form-hash">
        <div class="mint-form-hash-loader">
          <img :src="`https://cdn.hashku.com/assets/tail-spin-black.svg`" style="width: 20px" />
        </div>
        <div class="mint-form-hash-body">
          <div class="mint-form-hash-title">Transaction in progress:</div>
          <div>
            <a :href="getEtherscanLink(hash)" rel="noref noopener" target="_blank" class="mint-form-hash-link"> See pending transaction on Etherscan </a>
          </div>
        </div>
      </div>
    </div>

    <div class="mint-form-hashes" v-if="hashesToCheck?.length">
      <div v-for="(hash, h) in hashesToCheck" :key="h" class="mint-form-hash">
        <div class="mint-form-hash-loader">
          <img :src="`https://cdn.hashku.com/assets/tail-spin-black.svg`" style="width: 20px" />
        </div>
        <div class="mint-form-hash-body">
          <div class="mint-form-hash-title">Mint in progress:</div>
          <div>
            <a :href="getEtherscanLink(hash)" rel="noref noopener" target="_blank" class="mint-form-hash-link"> See pending transaction on Etherscan </a>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
