<script setup>
import { defineProps, onMounted, onUnmounted, defineEmits } from "vue";

const props = defineProps({
  contract: Object,
});

const emit = defineEmits(["updated", "transfer"]);

const connect = async () => {
  props.contract?.on("Transfer", (evt) => {
    console.log("Transfer: ", { evt });
    emit("transfer");
  });

  props.contract?.on("ContractUpdated", (evt) => {
    console.log("ContractUpdated: ", { evt });
    emit("updated");
  });
};

const disconnect = async () => {
  props.contract?.removeAllListeners();
};

onMounted(() => {
  connect();
});

onUnmounted(() => {
  disconnect();
});
</script>

<template><div></div></template>
