<script setup>
import { ref, defineProps, reactive, onMounted, defineEmits, computed } from "vue";
import axios from "axios";
import ConnectWallet from "./ConnectWallet.vue";

const emit = defineEmits(["updated", "logout", "error"]);

const props = defineProps({
  apiUrl: String,
  bearerToken: String,
  user: Object,
  registrant: Object,
  teamSlug: String,
  projectSlug: String,
  signer: Object,
  address: String,
  infuraId: String,
});

const loading = ref(false);

const logout = async () => {
  loading.value = true;
  let req = await axios.post(`${props.apiUrl}/logout`, null, {
    headers: {
      Authorization: `Bearer ${props.bearerToken}`,
    },
  });
  loading.value = false;
  emit("logout");
};

const emailEnable = ref(props.registrant?.email_alerts ?? false);

const updateNotifications = async () => {
  loading.value = true;
  try {
    let req = await axios.post(
      `${props.apiUrl}/notifications?team_slug=${props.teamSlug}&project_slug=${props.projectSlug}`,
      {
        enable_email: emailEnable.value ? "yes" : "no",
      },
      {
        headers: {
          Authorization: `Bearer ${props.bearerToken}`,
        },
      }
    );
    emit("updated", "Notification preferences updated!");
  } catch (e) {
    console.log("notification preference update error", e);
    emit("error", e?.message);
  }
  loading.value = false;
};

const detailDisplay = ref(false);
const showDetail = () => {
  detailDisplay.value = true;
};
const hideDetail = () => {
  detailDisplay.value = false;
};

const lockedAddress = computed(() => {
  if (!props.registrant?.eth_wallet_address) {
    return null;
  }

  return shortenAddress(props.registrant?.eth_wallet_address);
});

const shortenAddress = (add) => {
  if (!add) {
    return null;
  }

  return `${add?.substring(0, 5)}...${add?.substring(add?.length - 8)}`;
};

const message = "Verify your wallet with Hashku.";

const setWallet = async () => {
  let signature;
  try {
    signature = await props.signer.signMessage(message);
  } catch (e) {
    emit("error", "Signature declined by user");
    return false;
  }
  try {
    let sig = await axios.post(
      `${props.apiUrl}/wallet`,
      {
        address: props.address,
        signature,
      },
      {
        headers: {
          Authorization: `Bearer ${props.bearerToken}`,
        },
      }
    );
  } catch (e) {
    emit("error", e);
  }
  emit("updated", "Wallet set by user");

  return true;
};

const connectedWallet = (obj) => {
  emit("updated", "Wallet connected", obj);
};

const connectWalletError = (e) => {
  emit("error", e, true);
};
</script>

<template>
  <div class="account-component">
    <div class="action-link" v-if="!detailDisplay" @click="showDetail">Manage Your Alert / Account Settings</div>
    <div v-if="detailDisplay">
      <div class="account-section account-section-first"><strong>Email:</strong> {{ user.email }}</div>
      <div class="account-section" v-if="registrant.group"><strong>Your Presale Mint Group:</strong> {{ registrant.group }}</div>
      <div class="account-section" v-if="user.eth_wallet_address">
        <strong>Wallet Address:</strong> {{ shortenAddress(user.eth_wallet_address) }}<br />
        <span class="link" @click="setWallet" v-if="address">Update default wallet</span>
      </div>
      <div class="account-section" v-if="!user.eth_wallet_address">
        <strong>Wallet Address:</strong> <em>Not yet set</em><br />
        <span class="link" @click="setWallet" v-if="address">Update default wallet</span>
      </div>
      <div v-if="!address">
        <ConnectWallet @connected="connectedWallet" @error="connectWalletError" :infura-id="infuraId" type="link" />
      </div>
      <div class="account-section" v-if="lockedAddress"><strong>Locked Presale Wallet Address:</strong> {{ lockedAddress }}</div>
      <div class="account-section">
        <div>
          <label for="email-notifications"><strong>Enable Email Alerts For This Mint</strong></label>
        </div>
        <select v-model="emailEnable" @change="updateNotifications" name="email-notifications" class="account-email-input">
          <option :value="false">No</option>
          <option :value="true">Yes</option>
        </select>
      </div>
      <div class="account-section account-section-actions">
        <div class="action-link" @click="hideDetail">Minimize</div>
        <div class="action-link" @click="logout">Log Out</div>
      </div>
    </div>
  </div>
</template>
