const isObj = (obj) => {
  return typeof obj === "object" && obj !== null;
};

const getObjectKeyValues = (obj) => {
  let keys = Object.keys(obj);
  let arrayOfMessages = [];

  keys.forEach((k) => {
    if (Array.isArray(obj[k])) {
      obj[k].forEach((i) => {
        arrayOfMessages.push(i);
      });
    } else {
      arrayOfMessages.push(obj[k]);
    }
  });

  return arrayOfMessages;
};

const errorParse = (errors) => {
  let list = errors;

  if (typeof errors === "object" && errors !== null) {
    list = getObjectKeyValues(errors);
  }

  return list;
};

export default class Toast {
  constructor({ id, timeout, message, title, variant, dismissible } = {}) {
    this.timeout = timeout === false ? false : timeout ?? 4000;
    this.messages = isObj(message) ? errorParse(message) : Array.isArray(message) ? message : [message];
    this.id = id;
    this.title = title;
    this.variant = variant ?? "default";
    this.dismissible = dismissible === false ? false : true;
  }
}
