<script setup>
import Web3Modal from "web3modal";
import { ethers } from "ethers";
import WalletConnectProvider from "@walletconnect/web3-provider";
import { shallowReactive, defineEmits, defineProps } from "vue";

const props = defineProps(["signer", "provider", "address", "network", "infuraId", "type"]);
const emit = defineEmits(["connected", "error"]);

const providerOptions = props.infuraId
  ? {
      walletconnect: {
        package: WalletConnectProvider, // required
        options: {
          infuraId: props.infuraId, // required
          qrcodeModalOptions: {
            mobileLinks: ["rainbow", "metamask", "argent", "trust", "imtoken", "pillar"],
            desktopLinks: ["encrypted ink", "rainbow", "metamask", "argent", "trust", "imtoken", "pillar"],
          },
        },
      },
    }
  : {};

const web3Modal = new Web3Modal({
  // network: "rinkeby", // optional
  cacheProvider: false, // optional
  providerOptions, // required
});

const connection = shallowReactive({
  signer: null,
  provider: null,
  address: null,
  network: null,
});

const refreshData = async () => {
  if (connection.provider) {
    connection.provider.provider.removeAllListeners();
  }

  let provider = null;
  let signer = null;
  let address = null;
  let network = null;

  try {
    const instance = await web3Modal.connect();
    provider = new ethers.providers.Web3Provider(instance);
    signer = await provider.getSigner();
    address = await signer.getAddress();
    network = await provider.getNetwork();
  } catch (e) {
    console.log("web3 rejected", { e });
    emit("error", e?.message ?? e);
    return;
  }

  connection.provider = provider;
  connection.signer = signer;
  connection.address = address;
  connection.network = network;

  emit("connected", connection);

  provider.provider.on("accountsChanged", (accounts) => {
    console.log("account changed: ", accounts);
    refreshData();
  });

  // Subscribe to chainId change
  provider.provider.on("chainChanged", (chainId) => {
    console.log("Chain changed: ", chainId);
    refreshData();
  });

  // Subscribe to session disconnection
  provider.provider.on("disconnect", (code, reason) => {
    console.log("Walled disconnected: ", code, reason);
    connection.address = null;
    connection.signer = null;
    connection.provider = null;
    connection.network = null;
    emit("connected", connection);
  });

  return {
    signer,
    address,
    network,
    provider,
  };
};

async function handleWalletConnect() {
  let data;

  try {
    await web3Modal.clearCachedProvider();
    data = await refreshData();
  } catch (e) {
    console.log("web3 rejected", { e });
    emit("error", e?.message ?? e);
    return;
  }

  console.log({ data });
}
</script>

<template>
  <div class="connect-component">
    <!-- <h1>Connect Wallet</h1> -->
    <div class="connect-form">
      <div class="connect-link" v-if="type === 'link'" @click="handleWalletConnect">Connect Wallet</div>
      <button type="button" class="btn" @click="handleWalletConnect" v-else>Connect Wallet</button>
    </div>
  </div>
</template>
