<script setup>
import { ref, defineProps, reactive, onMounted, defineEmits, defineExpose } from "vue";
import Toast from "../classes/Toast";
import ToastVue from "./Toast.vue";

// const emit = defineEmits(["updated"]);

// const props = defineProps({});

const state = reactive({
  stack: [],
  index: 1,
});

const add = (payload) => {
  let added = payload;
  if (!(payload instanceof Toast)) {
    added = new Toast({ ...payload, id: state.index });
  }

  state.index += 1;
  state.stack = [...state.stack, added];
};

const remove = (payload) => {
  let removed = payload;
  if (!(payload instanceof Toast)) {
    removed = new Toast(payload);
  }

  let newStack = state.stack.slice();
  const foundIndex = removed.id ? newStack.findIndex((val) => val.id === removed.id) : newStack.findIndex((val) => val.message === removed.message);
  newStack.splice(foundIndex, 1);
  state.stack = [...newStack];
};

const clearAll = () => {
  state.stack = [];
};

defineExpose({
  add,
  remove,
  clearAll,
});
</script>

<template>
  <div class="toast-component">
    <div class="toast-component-inner">
      <ToastVue v-for="(toast, t) in state.stack" @remove="remove" :key="t" :toast="toast" />
    </div>
  </div>
</template>
