<script setup>
import { defineProps, onMounted, defineEmits, ref } from "vue";
import axios from "axios";

const props = defineProps({
  bearerToken: String,
  apiUrl: String,
  teamSlug: String,
});

const open = ref(false);

const emit = defineEmits(["error", "success"]);

const code = ref(null);

const submitCode = async () => {
  if (!code.value) {
    emit("error", "Code cannot be empty");
    return;
  }
  try {
    let submit = await axios.post(
      `${props.apiUrl}/invite?team_slug=${props.teamSlug}`,
      {
        code: code.value,
      },
      {
        headers: {
          Authorization: `Bearer ${props.bearerToken}`,
        },
      }
    );
  } catch (e) {
    emit("error", e?.response?.data?.errors ?? "Invalid code or maximum uses reached");
    return;
  }

  emit("success", "Presale access granted");
  open.value = false;
  code.value = null;
  return true;
};

onMounted(() => {});
</script>

<template>
  <div class="code-component">
    <div v-if="!open">If you have a code for presale access, <span @click="() => (open = true)" class="signature-link">click here to enter it</span></div>
    <div v-if="open">
      <label class="label">Invite code</label>
      <input type="text" v-model="code" class="text-input" placeholder="e.g. ABC123" />
      <button type="button" class="btn" @click="submitCode">Submit</button>
      <div @click="() => (open = false)" class="code-cancel">Cancel</div>
    </div>
  </div>
</template>
