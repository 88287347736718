<script setup>
import { ref, defineProps, computed, onMounted, defineEmits, onUnmounted } from "vue"; // onMounted, onUnmounted, computed,

const props = defineProps({
  url: String,
});

const listener = (event) => {
  console.log(`Received message: ${event.data}`);

  if (event.data == "closeWinterCheckoutModal") {
    emit("close");
  }
};

const emit = defineEmits(["close"]);

onMounted(() => {
  window.addEventListener("message", listener);
});

onUnmounted(() => {
  window.removeEventListener("message", listener);
});
</script>

<template>
  <iframe id="winter-checkout" :src="url" allowtransparency="true" className="winter-iframe"></iframe>
</template>
