<script setup>
import { defineProps, onMounted, defineEmits } from "vue";
import axios from "axios";

const props = defineProps({
  signer: Object,
  address: String,
  bearerToken: String,
  apiUrl: String,
});

const emit = defineEmits(["error", "success"]);
const message = "Verify your wallet with Hashku.";

const setWallet = async () => {
  let signature;
  try {
    signature = await props.signer.signMessage(message);
  } catch (e) {
    emit("error", "Signature declined by user");
    return false;
  }
  try {
    let sig = await axios.post(
      `${props.apiUrl}/wallet`,
      {
        address: props.address,
        signature,
      },
      {
        headers: {
          Authorization: `Bearer ${props.bearerToken}`,
        },
      }
    );
  } catch (e) {
    emit("error", e);
  }
  emit("success", "Wallet set by user");

  return true;
};

onMounted(() => {});
</script>

<template>
  <div class="signature-component">
    <div>If you were given presale access with your wallet; <span @click="setWallet" class="signature-link">connect wallet here</span></div>
  </div>
</template>
