<script setup>
import { computed, ref, onMounted, shallowReactive, reactive, defineProps, shallowRef, onUnmounted } from "vue";
import ConnectWallet from "./components/ConnectWallet.vue";
import axios from "axios";
import { ethers } from "ethers";
import Auth from "./components/Auth.vue";
import Mint from "./components/Mint.vue";
import abi from "./assets/abi.json";
import tokenabi from "./assets/tokenabi.json";
import Contract from "./classes/Contract";
import TokenContract from "./classes/TokenContract";
import User from "./classes/User";
import Registrant from "./classes/Registrant";
import ContractEvents from "./components/ContractEvents.vue";
import Title from "./components/Title.vue";
import { get as lsGet, remove as lsRemove, set as lsSet } from "local-storage";
import Account from "./components/Account.vue";
import PresaleLine from "./components/PresaleLine.vue";
import Toasts from "./components/Toasts.vue";
import SetWallet from "./components/SetWallet.vue";
import SubmitCode from "./components/SubmitCode.vue";
import WinterMint from "./components/WinterMint.vue";

const props = defineProps({
  apiUrl: String,
  teamSlug: String,
  projectSlug: String,
  infuraId: String,
  contractAddress: String,
  contractNetwork: String,
  eligibleCheck: String,
  maxTransaction: String,
  maxWallet: String,
  mintingColor: String,
  pendingColor: String,
  signupOnly: String,
  messageSignup: String,
  mintNow: String,
  backgroundColor: String,
  winterUrl: String,
  tokenMintSymbol: String,
});

const loading = ref(false);
const connection = shallowReactive({
  signer: null,
  provider: null,
  address: null,
  network: null,
  contract: null,
  tokenContract: null,
});
const tokenName = "hashkutoken";
const bearerToken = ref(lsGet(tokenName));
const isLoggedIn = computed(() => {
  return !!bearerToken.value;
});

const isSignupOnly = computed(() => {
  return props.signupOnly === "yes";
});

const isMintNow = computed(() => {
  return props.mintNow === "yes";
});

const hashkuData = reactive({
  user: new User(),
  registrant: new Registrant(),
});

const configuredForMint = computed(() => {
  return props.contractAddress && props.contractNetwork;
});

const setAuth = (token) => {
  bearerToken.value = token;
  lsSet(tokenName, bearerToken.value);
  successToast("Auth success", "Logged in!");
  fetchInfo();
};

const contractInstance = shallowRef(null);
const tokenContractInstance = shallowRef(null);
const connectToContract = async () => {
  let contract, paymentTokenAddress;
  if (configuredForMint.value && onRightNetwork.value) {
    contractInstance.value = await new ethers.Contract(props.contractAddress, abi.abi, connection.signer);

    let tokensMinted, maxMintPerTransaction, paymentTokenPrice, paymentTokenBalance, paymentTokenAllowance;

    try {
      tokensMinted = await contractInstance.value.tokensMinted(connection.address);
    } catch (e) {
      // cannot get tokenMinted
    }

    try {
      maxMintPerTransaction = await contractInstance.value.maxMintPerTransaction();
    } catch (e) {
      // cannot get maxMintPerTransaction
    }

    if (props.tokenMintSymbol) {
      try {
        paymentTokenAddress = await contractInstance.value.paymentTokenAddress();
      } catch (e) {
        // cannot get maxMintPerTransaction
      }

      try {
        paymentTokenPrice = await contractInstance.value.paymentTokenPrice();
      } catch (e) {
        // cannot get maxMintPerTransaction
      }

      try {
        paymentTokenBalance = await contractInstance.value.paymentTokenBalance();
      } catch (e) {
        // cannot get maxMintPerTransaction
      }

      try {
        paymentTokenAllowance = await contractInstance.value.paymentTokenAllowance();
      } catch (e) {
        // cannot get maxMintPerTransaction
      }
    }

    try {
      contract = new Contract(
        {
          address: props.contractAddress,
          chainId: props.contractNetwork,
          price: await contractInstance.value.price(),
          eligible: await contractInstance.value.eligible(),
          group: await contractInstance.value.group(),
          key: await contractInstance.value.key(),
          isPublic: await contractInstance.value.isPublic(),
          isClosed: await contractInstance.value.isClosed(),
          maxTokens: await contractInstance.value.maxTokens(),
          nextToken: await contractInstance.value.nextToken(),
          tokensMinted,
          balanceOf: await contractInstance.value.balanceOf(connection.address),
          maxMintPerAddress: await contractInstance.value.maxMintPerAddress(),
          maxMintPerTransaction,
          paymentTokenAddress,
          paymentTokenPrice,
          paymentTokenBalance,
          paymentTokenAllowance,
          paymentTokenSymbol: props.tokenMintSymbol,
        },
        hashkuData.registrant,
        {
          useEligibleCheck: props.eligibleCheck === "yes",
          maxTransaction: props.maxTransaction,
          maxWallet: props.maxWallet,
        }
      );
    } catch (e) {
      errorToast("Contract connection error", "Error establishing a connection to the contract");
    }
    console.log("contract connected", contract);
  } else {
    console.log("couldn't connect contract");
  }

  if (contract?.isMintActive) {
    open.value = true;
  }

  connection.contract = contract;

  if (props.tokenMintSymbol && paymentTokenAddress) {
    tokenContractInstance.value = await new ethers.Contract(paymentTokenAddress, tokenabi.abi, connection.signer);
  }
};

const fetchInfo = async (setLoader = true) => {
  if (setLoader) {
    loading.value = true;
  }

  if (connection.provider) {
    // don't even fetch info unless wallet is connected
    await connectToContract();
  }

  // if contract is not public, and not closed, and the user is logged in, get Hashku registrant data
  if (bearerToken.value && (isSignupOnly.value || (!connection.contract?.isPublic && !connection.contract?.isClosed))) {
    let req;
    try {
      req = await axios.get(`${props.apiUrl}/info?team_slug=${props.teamSlug}`, {
        headers: {
          Authorization: `Bearer ${bearerToken.value}`,
        },
      });
    } catch (e) {
      bearerToken.value = null;
      lsRemove(tokenName);
    }
    hashkuData.user = req?.data?.content?.user;
    hashkuData.registrant = req?.data?.content?.registrant;

    if (!hashkuData.user?.id) {
      // no user came back, remove bearertoken if being stored
      bearerToken.value = null;
      lsRemove(tokenName);
    }

    console.log("Hashku request completed", { content: req?.data?.content });

    try {
      // check the contract again and will insert registrant data if applicable
      await connectToContract();
    } catch (e) {
      console.log("error occurred, update ui", e?.response?.data?.errors);
      errorToast("Contract error", e?.response?.data?.errors?.join(", ") ?? "Could not retrieve contract data");
    }
  }

  loading.value = false;
};

const logoutEvent = () => {
  hashkuData.user = new User();
  hashkuData.registrant = new Registrant();
  bearerToken.value = null;
  lsRemove(tokenName);
  fetchInfo();
};

const authErrorEvent = (e) => {
  errorToast("Authentication error", e);
};

const contractUpdatedEvent = () => {
  console.log("contract updated");
  fetchInfo();
};

const contractTransferEvent = () => {
  console.log("contract transfer");
  connectToContract();
};

const mintErrorEvent = (val) => {
  errorToast("Mint error", val);
};

const mintSuccessEvent = (val) => {
  successToast("Mint success", val);
  fetchInfo();
};

const mintRefreshEvent = (val) => {
  fetchInfo();
};

const wallectConnectedEvent = (obj) => {
  console.log("wallet connected", obj);
  connection.address = obj.address;
  connection.signer = obj.signer;
  connection.provider = obj.provider;
  connection.network = obj.network;
  connection.contract = null;
  fetchInfo();
};

const walletConnectFailureEvent = (msg) => {
  console.log("wallet connect error", msg);

  connection.address = null;
  connection.signer = null;
  connection.provider = null;
  connection.network = null;
  connection.contract = null;

  errorToast("Wallet connection error", msg);
};

const setWalletSuccessEvent = (msg) => {
  successToast("Set wallet success", msg);
  fetchInfo();
};

const setWalletErrorEvent = (msg) => {
  errorToast("Set wallet error", msg);
};

const codeSubmitSuccessEvent = (msg) => {
  successToast("Invite code success", msg);
  fetchInfo();
};

const codeSubmitErrorEvent = (msg) => {
  errorToast("Invite code error", msg);
};

const accountUpdateSuccessEvent = (msg, walletUpdate = null) => {
  if (walletUpdate) {
    wallectConnectedEvent(walletUpdate);
  } else {
    successToast("Account update success", msg);
    fetchInfo();
  }
};

const accountUpdateErrorEvent = (msg, clearConnection = false) => {
  errorToast("Account update error", msg);
  if (clearConnection) {
    connection.address = null;
    connection.signer = null;
    connection.provider = null;
    connection.network = null;
    connection.contract = null;
  }
};

const user = computed(() => {
  return hashkuData?.user?.id ? hashkuData.user : null;
});

const registrant = computed(() => {
  return hashkuData?.registrant?.id ? hashkuData.registrant : null;
});

const onRightNetwork = computed(() => {
  if (!connection.network?.chainId) {
    return false;
  }

  if (props.contractNetwork) {
    return connection.network?.chainId == props.contractNetwork;
  }

  return false;
});

// ALLOW THE WIDGET TO CLOSE/OPEN
const open = ref(false);
const toggleOpen = () => {
  open.value = !open.value;
};
const setOpen = (op) => {
  open.value = op;
};

const isPublicMint = computed(() => {
  return connection.contract?.isPublic;
});

const isClosedMint = computed(() => {
  return connection.contract?.isClosed;
});

const mintingColor = computed(() => {
  return props.mintingColor ?? "#00dda8";
});

const pendingColor = computed(() => {
  return props.pendingColor ?? "#dd00ac";
});

const backgroundColor = computed(() => {
  return props.backgroundColor ?? "#ffffff";
});

const actionColor = computed(() => {
  if (connection.contract?.isMintable && connection.contract?.isMintActive) {
    return mintingColor.value;
  }

  if (connection.contract?.isMintActive || isSignupOnly.value) {
    return pendingColor.value;
  }

  return "#a9a9a9";
});

const successToast = (title, message) => {
  if (toasts.value) {
    toasts.value?.add({ title, message, variant: "success" });
  }
};

const errorToast = (title, message) => {
  if (toasts.value) {
    toasts.value?.add({ title, message, variant: "error" });
  }
};

const intervalVal = ref(null);
// ACTIONS THAT HAPPEN AUTOMATICALLY
onMounted(() => {
  intervalVal.value = setInterval(() => {
    console.log("auto refetch");
    fetchInfo(false);
  }, 5 * 60 * 1000);

  if (isLoggedIn.value) {
    fetchInfo(false);
  }
});

onUnmounted(() => {
  clearInterval(intervalVal.value);
});

const setLoading = (val) => {
  loading.value = val;
};

const networkName = computed(() => {
  if (props.contractNetwork) {
    switch (props.contractNetwork) {
      case "1":
      case 1:
        return `Ethereum Mainnet`;
      case "3":
      case 3:
        return `Ethereum Ropsten`;
      case "4":
      case 4:
        return `Ethereum Rinkeby`;
      case "5":
      case 5:
        return `Ethereum Goerli`;
      case "42":
      case 42:
        return `Ethereum Kovan`;
      case "1337":
      case 1337:
        return `Test Network`;
    }
  }
  return null;
});

const toasts = ref(null);

const showWinter = ref(false);
const openWinter = () => {
  showWinter.value = true;
};
const closeWinter = () => {
  showWinter.value = false;
};
</script>

<template>
  <div class="hashku-mint">
    <Toasts ref="toasts" />
    <div class="logo-container" @click="toggleOpen" :style="{ 'background-color': actionColor }">
      <svg class="logo" version="1.1" id="Layer_1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" x="0px" y="0px" viewBox="0 0 144 144" style="enable-background: new 0 0 144 144" xml:space="preserve">
        <path
          class="st0"
          d="M110.89,40.19c-0.1-0.13-0.25-0.22-0.42-0.23l-11.79-1.38c-0.35-0.04-0.66,0.21-0.7,0.55l-2.67,22.77h-20.6
	l4.5-38.44c0.02-0.17-0.03-0.33-0.13-0.46c-0.1-0.13-0.25-0.22-0.42-0.23l-11.79-1.38c-0.17-0.02-0.33,0.03-0.46,0.13
	c-0.13,0.1-0.22,0.25-0.23,0.42l-4.68,39.97H35.5c-0.35,0-0.63,0.28-0.63,0.63v11.87c0,0.35,0.28,0.63,0.63,0.63h24.47l-2.04,17.45
	H31.6c-0.35,0-0.63,0.28-0.63,0.63v11.87c0,0.35,0.28,0.63,0.63,0.63h24.79l-1.52,12.96c-0.02,0.17,0.03,0.33,0.13,0.46
	c0.1,0.13,0.25,0.22,0.42,0.23l11.79,1.38c0.02,0,0.05,0,0.07,0c0.31,0,0.59-0.24,0.62-0.56l1.7-14.49h11.8
	c0.35,0,0.63-0.28,0.63-0.63V93.1c0-0.35-0.28-0.63-0.63-0.63H71.14l2.04-17.45h20.6l-2.6,22.17c-0.04,0.34,0.21,0.66,0.55,0.7
	l11.79,1.38c0.02,0,0.05,0,0.07,0c0.31,0,0.59-0.24,0.63-0.56l6.8-58.06l0,0C111.04,40.48,110.99,40.32,110.89,40.19z"
        />
      </svg>
    </div>
    <div class="widget" :style="{ 'background-color': backgroundColor }">
      <div class="content w-full" v-if="open">
        <div v-if="isSignupOnly" style="width: 100%">
          <div v-if="!hashkuData.user?.id">
            <Title :color="actionColor" top="Sign up for presale"></Title>
            <Auth :api-url="apiUrl" :team-slug="teamSlug" @updated="setAuth" @error="authErrorEvent" :message-signup="messageSignup" />
          </div>
          <div v-else>
            <Title :color="actionColor" top="Mint Pending"></Title>
            <PresaleLine v-if="registrant?.is_presale" />
            <div class="account-prefix-section" v-if="!registrant?.is_presale && !user.eth_wallet_address">
              <SetWallet :signer="connection.signer" :bearer-token="bearerToken" :api-url="apiUrl" :address="connection.address" :loading="loading" @error="setWalletErrorEvent" @success="setWalletSuccessEvent" />
            </div>
            <div class="account-prefix-section">
              <SubmitCode :api-url="apiUrl" :bearer-token="bearerToken" :team-slug="teamSlug" @success="codeSubmitSuccessEvent" @error="codeSubmitErrorEvent" />
            </div>
            <Account :user="user" :registrant="registrant" @logout="logoutEvent" @updated="accountUpdateSuccessEvent" @error="accountUpdateErrorEvent" :bearer-token="bearerToken" :api-url="apiUrl" :team-slug="teamSlug" :project-slug="projectSlug" :signer="connection.signer" :address="connection.address" :infura-id="infuraId" />
          </div>
        </div>
        <div style="width: 100%" v-else-if="!connection.address">
          <ConnectWallet @connected="wallectConnectedEvent" @error="walletConnectFailureEvent" :infura-id="infuraId" />
        </div>
        <div style="width: 100%" v-else>
          <div v-if="!onRightNetwork">
            <Title :color="actionColor" :top="`Connect to ${networkName}`" :bottom="`Please ensure your wallet is connected to the ${networkName}`"></Title>
          </div>
          <div v-else-if="connection.contract">
            <!-- contract configured -->
            <template v-if="connection.contract.phase === 'open_public'">
              <div v-if="connection.contract.registrantPhase === 'ineligible'">
                <Title :color="actionColor" top="Mint Ineligible" bottom="You are ineligible to mint at this time"></Title>
              </div>
              <div v-else>
                <Title :color="actionColor" top="Mint Now"></Title>
                <Mint
                  :registrant="registrant"
                  :signer="connection.signer"
                  :provider="connection.provider"
                  :network="connection.network"
                  :bearer-token="bearerToken"
                  :api-url="apiUrl"
                  :contract="connection.contract"
                  :address="connection.address"
                  :contract-instance="contractInstance"
                  :token-contract-instance="tokenContractInstance"
                  :team-slug="teamSlug"
                  :project-slug="projectSlug"
                  :loading="loading"
                  @loaded="
                    (val) => {
                      setLoading(val);
                    }
                  "
                  @error="mintErrorEvent"
                  @success="mintSuccessEvent"
                  @refresh="mintRefreshEvent"
                  :minting-color="props.mintingColor"
                />
              </div>
            </template>
            <template v-else-if="connection.contract.phase === 'closed'">
              <Title :color="actionColor" top="Minting Closed"></Title>
            </template>
            <template v-else-if="connection.contract.phase === 'closed_pending'">
              <Title :color="actionColor" top="Minting Closed"></Title>
            </template>
            <template v-else-if="connection.contract.phase === 'closed_sold_out'">
              <Title :color="actionColor" top="Mint Sold Out!"></Title>
            </template>
            <template v-else-if="connection.contract.phase === 'pending_presale_paused'">
              <Title :color="actionColor" top="Minting Paused"></Title>
            </template>
            <template v-else-if="connection.contract.phase === 'pending'">
              <Title :color="actionColor" top="Minting Not Started" :bottom="`${!user?.id ? '' : 'You are registered for this mint'}`"></Title>
            </template>
            <template v-else-if="connection.contract.phase === 'open_presale'">
              <div v-if="connection.contract.registrantPhase === 'yes_group'">
                <Title :color="actionColor" top="Mint Now"></Title>
                <Mint
                  :registrant="registrant"
                  :signer="connection.signer"
                  :provider="connection.provider"
                  :network="connection.network"
                  :bearer-token="bearerToken"
                  :api-url="apiUrl"
                  :contract="connection.contract"
                  :address="connection.address"
                  :contract-instance="contractInstance"
                  :team-slug="teamSlug"
                  :project-slug="projectSlug"
                  :loading="loading"
                  @loaded="
                    (val) => {
                      setLoading(val);
                    }
                  "
                  @error="mintErrorEvent"
                  @success="mintSuccessEvent"
                  @refresh="mintRefreshEvent"
                  :minting-color="props.mintingColor"
                />
              </div>
              <div v-else-if="connection.contract.registrantPhase === 'pending_group'">
                <Title :color="actionColor" top="Presale Mint in Progress" :bottom="`Group ${connection.contract.groupNumber} is minting, your group is ${connection.contract.registrantGroup}`"></Title>
              </div>
              <div v-else-if="connection.contract.registrantPhase === 'pending_group_assign'">
                <Title :color="actionColor" top="Presale Mint in Progress" :bottom="`Group ${connection.contract.groupNumber} is minting, you will be assigned a group`"></Title>
              </div>
              <div v-else-if="connection.contract.registrantPhase === 'pending_public'">
                <Title :color="actionColor" top="Presale Mint in Progress" :bottom="isLoggedIn ? 'You can mint during the public mint' : ''"></Title>
              </div>
              <div v-else-if="connection.contract.registrantPhase === 'ineligible'">
                <Title :color="actionColor" top="Mint Ineligible" bottom="You are ineligible to mint at this time"></Title>
              </div>
            </template>

            <div v-if="connection.contract.isRegisterable">
              <div v-if="!hashkuData.user?.id">
                <Auth :api-url="apiUrl" :team-slug="teamSlug" @updated="setAuth" @error="authErrorEvent" :message-signup="messageSignup" />
              </div>
              <div v-else>
                <PresaleLine v-if="registrant?.is_presale" />
                <div class="account-prefix-section" v-if="!registrant?.is_presale && !user.eth_wallet_address">
                  <SetWallet :signer="connection.signer" :bearer-token="bearerToken" :api-url="apiUrl" :address="connection.address" :loading="loading" @error="setWalletErrorEvent" @success="setWalletSuccessEvent" />
                </div>
                <div class="account-prefix-section" v-if="!registrant?.is_presale || registrant?.group != 1">
                  <SubmitCode :api-url="apiUrl" :bearer-token="bearerToken" :team-slug="teamSlug" @success="codeSubmitSuccessEvent" @error="codeSubmitErrorEvent" />
                </div>
                <Account :user="user" :registrant="registrant" @logout="logoutEvent" @updated="accountUpdateSuccessEvent" @error="accountUpdateErrorEvent" :bearer-token="bearerToken" :api-url="apiUrl" :team-slug="teamSlug" :project-slug="projectSlug" :signer="connection.signer" :address="connection.address" />
              </div>
            </div>

            <div class="own-box" v-if="connection.contract?.balanceOf">You own {{ connection.contract.balanceOf }} of these</div>
            <ContractEvents :contract="contractInstance" v-if="contractInstance" @updated="contractUpdatedEvent" @transfer="contractTransferEvent" />
          </div>
          <div v-else>
            <!-- contract not configured -->
            <Title :color="actionColor" top="Minting Not Started"></Title>
            <div v-if="!hashkuData.user?.id">
              <Auth :api-url="apiUrl" :team-slug="teamSlug" @updated="setAuth" @error="authErrorEvent" />
            </div>
            <div v-else>
              <p>You are registered for this mint</p>
              <PresaleLine v-if="registrant?.is_presale" />
              <Account :user="user" :registrant="registrant" @logout="logoutEvent" :bearer-token="bearerToken" :api-url="apiUrl" :team-slug="teamSlug" :project-slug="projectSlug" />
            </div>
          </div>
        </div>
        <div v-if="winterUrl" class="winter-link-container">
          <div class="winter-link" @click="openWinter">Or mint with a credit card</div>
          <WinterMint :url="winterUrl" v-if="showWinter" @close="closeWinter" />
        </div>
        <div class="powered-by">Mint Powered by <a href="https://www.hashku.com/" target="_blank">Hashku</a></div>
      </div>
      <div class="content" v-else @click="setOpen(true)">
        <div style="width: 100%">
          <h1 class="closed-title" :class="[actionColor]">
            <template v-if="connection.contract?.phase === 'open_public'">Mint Now</template>
            <template v-else-if="connection.contract?.phase === 'closed'">Minting Closed</template>
            <template v-else-if="connection.contract?.phase === 'closed_pending'">Mint Pending</template>
            <template v-else-if="connection.contract?.phase === 'closed_sold_out'">Mint Sold Out!</template>
            <template v-else-if="connection.contract?.phase === 'pending_presale_paused'">Minting Paused</template>
            <template v-else-if="connection.contract?.phase === 'pending'">{{ isLoggedIn ? "Mint Pending" : "Sign Up for Mint" }}</template>
            <template v-else-if="connection.contract?.phase === 'open_presale'">
              <div v-if="connection.contract?.registrantPhase === 'yes_group'">Presale Mint in Progress</div>
              <div v-else-if="connection.contract?.registrantPhase === 'pending_group'">Presale Mint in Progress</div>
              <div v-else-if="connection.contract?.registrantPhase === 'pending_group_assign'">Presale Mint in Progress</div>
              <div v-else-if="connection.contract?.registrantPhase === 'pending_public'">Presale Mint in Progress</div>
            </template>
            <template v-else>
              <template v-if="isLoggedIn">{{ isMintNow ? "Mint Now" : "Mint Pending" }}</template>
              <template v-else-if="isSignupOnly">Sign Up for Presale</template>
              <template v-else>Mint Now</template>
            </template>
          </h1>
        </div>
      </div>
      <div class="loader" v-if="loading">
        <svg width="38" height="38" viewBox="0 0 38 38" xmlns="http://www.w3.org/2000/svg">
          <defs>
            <linearGradient x1="8.042%" y1="0%" x2="65.682%" y2="23.865%" id="a">
              <stop stop-color="#fff" stop-opacity="0" offset="0%" />
              <stop stop-color="#fff" stop-opacity=".631" offset="63.146%" />
              <stop stop-color="#fff" offset="100%" />
            </linearGradient>
          </defs>
          <g fill="none" fill-rule="evenodd">
            <g transform="translate(1 1)">
              <path d="M36 18c0-9.94-8.06-18-18-18" id="Oval-2" stroke="url(#a)" stroke-width="2">
                <animateTransform attributeName="transform" type="rotate" from="0 18 18" to="360 18 18" dur="0.9s" repeatCount="indefinite" />
              </path>
              <circle fill="#fff" cx="36" cy="18" r="1">
                <animateTransform attributeName="transform" type="rotate" from="0 18 18" to="360 18 18" dur="0.9s" repeatCount="indefinite" />
              </circle>
            </g>
          </g>
        </svg>
      </div>
    </div>
  </div>
</template>

<!-- ALL Styles used within the widget need to be defined here so they are properly scoped and inlined into the final bundle -->
<style>
@import url("https://fonts.googleapis.com/css2?family=DM+Sans:ital,wght@0,400;0,700;1,400&display=swap");

html {
  -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box;
  box-sizing: border-box;
}

*,
*::before,
*::after {
  -webkit-box-sizing: inherit;
  -moz-box-sizing: inherit;
  box-sizing: inherit;
}

#WEB3_CONNECT_MODAL_ID,
.hashku-mint {
  font-family: "DM Sans", Arial, Helvetica, sans-serif;
}

.web3modal-modal-lightbox {
  z-index: 19999 !important;
}

.winter-iframe {
  top: 0px;
  bottom: 0px;
  right: 0px;
  width: 100%;
  border: none;
  margin: 0;
  padding: 0;
  overflow: hidden;
  z-index: 39999;
  height: 100%;
  display: inline;
  position: fixed;
}

.hashku-mint {
  width: 360px;
  display: block;
  padding: 16px 16px 0;
  position: fixed;
  bottom: 0;
  right: 10px;
  z-index: 9999;
}

.widget {
  display: flex;
  width: 100%;
  border-width: 1px 1px 1px 1px;
  border-color: #000000;
  border-style: solid;
  border-radius: 2px 2px 0 0;
  box-sizing: border-box;
  box-shadow: 4px 3px 4px rgba(0, 0, 0, 0.15);
  position: relative;
  padding: 16px;
}

.content {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  width: 100%;
}

.logo-container {
  background-color: #a9a9a9;
  border-radius: 30px;
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.15);
  padding: 4px;
  width: 42px;
  height: 42px;
  display: flex;
  align-items: center;
  justify-content: center;
  position: absolute;
  right: 0;
  top: 0;
  z-index: 500;
  cursor: pointer;
}

/* .logo-container.purple {
  background-color: #dd00ac;
}

.logo-container.green {
  background-color: #00dda8;
}

.purple {
  color: #dd00ac;
}

.green {
  color: #00dda8;
} */

.logo {
  align-self: center;
  height: 28px;
  fill: #fff;
}

.label {
  margin: 0;
  font-size: 12px;
  font-weight: bold;
}

.btn {
  background-color: white;
  border: 1px solid #000000;
  border-radius: 4px;
  box-sizing: border-box;
  box-shadow: 2px 2px 1px rgba(0, 0, 0, 0.25);
  color: black;
  cursor: pointer;
  padding: 12px;
  font-size: 20px;
  font-weight: 700;
  width: 100%;
}

.btn-disabled {
  background: #d4d4d4;
  border: none;
  color: #a8a8a8;
}

.btn-green {
  background: #00dda8;
  border: none;
  color: #ffffff;
  text-shadow: 1px 1px 0px rgba(0, 0, 0, 0.25);
}

.powered-by {
  color: rgba(0, 0, 0, 0.4);
  margin-top: 12px;
  font-size: 12px;
}

.text-shadow {
  text-shadow: 1px 1px 0px rgba(0, 0, 0, 0.25);
}

.action-link {
  cursor: pointer;
  text-decoration: underline;
  font-size: 12px;
}

.loader {
  position: absolute;
  opacity: 0.5;
  background-color: #000000;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  display: flex;
  justify-content: center;
  align-items: center;
}

.closed-title {
  cursor: pointer;
}

.own-box {
  background-color: #333333;
  color: #fff;
  font-size: 12px;
  margin-top: 12px;
  border-radius: 3px;
  text-align: center;
  padding: 5px 7px;
}

.account-section {
  margin: 12px 0 0;
  font-size: 12px;
}

.account-section-actions {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.account-section-first {
  margin: 0;
}

.account-email-input {
  background-color: rgb(251, 251, 251);
  border: none;
  box-shadow: inset 1px 1px 2px 1px lightgray;
  line-height: 32px;
  font-size: 12px;
  padding: 6px 8px;
  margin: 8px 0 0;
  width: 100%;
}

.mint-maximums {
  font-size: 12px;
  margin: 12px 0;
}

h1 {
  font-size: 20px;
  margin-top: 0;
  margin-bottom: 0;
}

p {
  font-size: 13px;
  margin: 6px 0;
}

.text-input {
  background-color: rgb(251, 251, 251);
  border: none;
  box-shadow: inset 1px 1px 2px 1px lightgray;
  line-height: 32px;
  font-size: 16px;
  padding: 8px 10px;
  margin: 0 0 16px;
  width: 100%;
}

.mint-form-hashes {
  margin-top: 16px;
}
.mint-form-hash {
  display: flex;
  align-items: center;
}
.mint-form-hash-loader {
  margin-right: 10px;
}
.mint-form-hash-link {
  font-size: 12px;
  color: #000;
  text-decoration: underline;
}

@media (max-width: 420px) {
  .hashku-mint {
    bottom: 0;
    width: 100%;
    right: 0;
    left: 0;
  }
}

/* DIVIDER */

.error-msg {
  color: red;
  font-size: 12px;
  padding: 4px 0;
}

.connect-component,
.login-component,
.register-component,
.mint-component,
.presale-component,
.public-mint-component {
  width: 100%;
}

.mint-component {
  margin-top: 16px;
}

.presale-component {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  margin-bottom: 14px;
}

.presale-line {
  display: flex;
  align-items: center;
  margin: 8px 0;
}

.mint-form-price {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 16px;
  width: 100%;
}

.mint-form-quantity {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  margin-bottom: 16px;
  width: 100%;
}

.mint-form-subtotal {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  margin-bottom: 16px;
  width: 100%;
}

.mint-form-registered-wallet {
  font-size: 12px;
  margin-top: 14px;
  text-align: center;
  color: rgba(216, 72, 72, 0.89);
}

.mint-quantity-input {
  background-color: rgb(251, 251, 251);
  border: none;
  box-shadow: inset 1px 1px 2px 1px lightgray;
  line-height: 32px;
  font-size: 12px;
  padding: 8px 10px;
  margin: 0 0 16px;
  width: 100%;
}

input:focus-visible,
input:focus {
  border: none;
  outline: none;
}

.mint {
  color: #00dda8;
}
.mint-submit {
  background-color: #00dda8;
  border: none !important;
  border-radius: 4px !important;
  box-sizing: border-box;
  box-shadow: 2px 2px 1px rgba(0, 0, 0, 0.25);
  color: white !important;
  font-size: 24px;
  font-weight: 700;
  cursor: pointer;
  margin-top: 16px;
  padding: 12px;
  width: 100%;
}
.mint-submit:disabled {
  background-color: lightgray !important;
}

.presale-component-group {
  align-items: center;
  display: flex;
  flex-direction: row;
  justify-content: center;
  margin-bottom: 6px;
}

.presale-component-group img {
  margin-left: -5px;
}

.presale-component-countdown {
  color: #00dda8;
  font-size: 16px;
  font-weight: 500;
  line-height: 21px;
}
.presale-component-manage-account {
  color: #000000;
  font-size: 13px;
  font-weight: 400;
  line-height: 17px;
}

.account-component {
  border-top: rgba(0, 0, 0, 0.15) 1px solid;
  border-bottom: rgba(0, 0, 0, 0.15) 1px solid;
  padding: 12px 0 16px;
  margin-top: 12px;
}

.toast-component {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
}

.toast-component-inner {
  position: absolute;
  bottom: 0;
  left: 0;
  right: 0;
  padding: 0 16px 5px;
}

.toast {
  color: #000;
  border-radius: 3px;
  overflow: hidden;
  margin-top: 8px;
}

.toast-timer {
  height: 5px;
}

.toast-inner {
  padding: 8px;
}

.toast-timer-variant-error {
  background-color: rgba(216, 72, 72, 0.89);
}

.toast-timer-variant-success {
  background-color: #00dda8;
}

.toast-inner-variant-error {
  background-color: rgba(250, 208, 208, 0.89);
}

.toast-inner-variant-success {
  background-color: #bcf5e8;
}

.toast-title {
  font-weight: bold;
  font-size: 12px;
}
.toast-message {
  font-size: 14px;
}

.signature-component {
  font-size: 14px;
  color: rgba(0, 0, 0, 0.75);
}

.signature-link {
  cursor: pointer;
  text-decoration: underline;
}

.code-component {
  font-size: 14px;
  color: rgba(0, 0, 0, 0.75);
}

.code-cancel {
  margin-top: 14px;
  text-decoration: underline;
  cursor: pointer;
}

.account-prefix-section {
  margin-bottom: 14px;
  border-top: rgba(0, 0, 0, 0.15) 1px solid;
  padding-top: 14px;
}

.link {
  cursor: pointer;
  text-decoration: underline;
}

.connect-link {
  cursor: pointer;
  text-decoration: underline;
  font-size: 12px;
}

.winter-link {
  text-decoration: underline;
  font-size: 16px;
  text-align: center;
  width: 100%;
  cursor: pointer;
}

.winter-link-container {
  margin-top: 12px;
  margin-bottom: 12px;
  width: 100%;
}

.mint-mode-switch {
  color: black;
  background-color: white;
  border: 1px solid #000000;
  border-radius: 4px;
  box-sizing: border-box;
  box-shadow: 2px 2px 1px rgba(0, 0, 0, 0.25);
  cursor: pointer;
  padding: 6px;
  font-size: 12px;
  font-weight: 700;
  /* width: 100%; */
  text-align: center;
}
</style>
