<script setup>
import { ref, defineProps, reactive, onMounted, defineEmits } from "vue";
import axios from "axios";
import { get as lsGet, remove as lsRemove, set as lsSet } from "local-storage";

const emit = defineEmits(["updated", "error"]);

const props = defineProps({
  apiUrl: String,
  teamSlug: String,
  messageSignup: String,
});

const loading = ref(false);

const fields = reactive({
  email: null,
  verification_string: null,
  token: null,
});

const signup = async () => {
  loading.value = true;
  let req;
  try {
    req = await axios.post(`${props.apiUrl}/signup?team_slug=${props.teamSlug}`, {
      email: fields.email,
    });
    fields.verification_string = req.data?.content?.verification_string;
  } catch (e) {
    console.log("signup error", e);
    emit("error", e?.response?.data?.errors ?? "Unable to sign up with the provided email");
  }
  loading.value = false;
};

const login = async () => {
  loading.value = true;
  let req;
  try {
    req = await axios.post(`${props.apiUrl}/login?team_slug=${props.teamSlug}`, {
      verification_string: fields.verification_string,
      token: fields.token,
    });
    emit("updated", req.data?.token);
  } catch (e) {
    console.log("login error", e);
    emit("error", e?.response?.data?.errors ?? "Incorrect verification token");
  }
  loading.value = false;
};
</script>

<template>
  <div class="login-component">
    <template v-if="!fields.verification_string">
      <p v-if="messageSignup">{{ messageSignup }}</p>
      <p v-else>Enter your email if you have pre-sale access. Otherwise, enter your email for a chance to gain pre-sale access.</p>
      <form class="login-form" @submit.prevent="signup">
        <label class="label">Email</label>
        <input class="text-input" type="text" placeholder="you@example.com" v-model="fields.email" />
        <button class="btn">Submit</button>
      </form>
    </template>
    <template v-else>
      <p>Hashku has sent you an email to confirm your address - go grab that token from your inbox (check your SPAM folder if you don't see it within a couple minutes).</p>
      <form class="login-form" @submit.prevent="login">
        <label class="label">Token</label>
        <input class="text-input" type="text" placeholder="ABC123" v-model="fields.token" />
        <button class="btn">Submit</button>
      </form>
    </template>
  </div>
</template>
