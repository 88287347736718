<script setup>
import { onBeforeUnmount, onMounted, ref, defineEmits, defineProps } from "vue";
// import Btn from "@/components/display/Btn";
// import Icon from "@/components/display/icon/Icon";
import Toast from "../classes/Toast";

const emit = defineEmits(["remove"]);

const props = defineProps({
  toast: Toast,
});

const timer = ref(props.toast?.timeout);
const interval = ref(null);
const countdownAmount = 25;

const close = () => {
  if (props.toast.dismissible) {
    emit("remove", props.toast);
  }
};

onMounted(() => {
  if (timer.value && timer.value > 0) {
    interval.value = setInterval(() => {
      if (timer.value <= 0) {
        close();
      }
      timer.value -= countdownAmount;
    }, countdownAmount);
  }
});

onBeforeUnmount(() => {
  if (interval.value) {
    clearInterval(interval.value);
  }
});
</script>

<template>
  <div class="toast" role="alert" aria-live="assertive" aria-atomic="true">
    <div class="toast-timer" :class="'toast-timer-variant-' + toast.variant" :style="{ width: toast.timeout ? `${(timer / toast.timeout) * 100}%` : '100%' }"></div>
    <div class="toast-inner" :class="'toast-inner-variant-' + toast.variant">
      <div class="toast-title" v-if="toast.title">{{ toast.title }}</div>
      <div class="toast-message" v-for="(message, m) in toast.messages" :key="m">
        {{ message }}
      </div>
      <!-- <div class="toast-action">
        <div class="toast-action-link" @click="close">close</div>
      </div> -->
    </div>
  </div>
</template>
