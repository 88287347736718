// https://maximomussini.com/posts/vue-custom-elements

import HashkuWidget from "./HashkuWidget.ce.vue";

import "@/assets/hashku.css";

import { defineCustomElement } from "vue";

const HashkuMintWidget = defineCustomElement(HashkuWidget);

// Register the custom element.
// After registration, all `<my-vue-element>` tags
// on the page will be upgraded.
customElements.define("hashku-mint", HashkuMintWidget);
